

































































































































































































import Vue from 'vue';
import API from '@/plugins/axios';
import { imageData, instituteValueToKey, instituteKeyToValue } from '@/utils/utilsData';

export default Vue.extend({
  name: 'Skills',
  data: () => ({
    imageData: { ...imageData },
    levels: [1, 2, 3],
    institutes: Object.keys(instituteKeyToValue),
    instituteValueToKey: instituteValueToKey,
    data: [] as unknown[],
    coursesCount: 0,
    coursesPage: 1,
    coursesFetched: false,
    skillsKitsData: [] as unknown[],
    skillsKitsDataCount: 0,
    skillsKitsFetched: false,
    skillsKitsDataPage: 1,
    selectedLevels: [],
    selectedInstitutes: [],
    loading: true,
    skillsKits: null as unknown as boolean,
    searchTerm: '',
  }),
  watch: {
    skillsKits(newVal) {
      localStorage.setItem('skillsKits', JSON.stringify(newVal));
      if (newVal) {
        this.getSkillsKits();
      } else {
        this.getCourses();
      }
    },
    selectedLevels(newVal) {
      this.coursesFetched = false;
      this.coursesPage = 1;
      this.data = [];
      if (newVal.length) {
        localStorage.setItem('selectedLevels', JSON.stringify(newVal));
      } else {
        localStorage.removeItem('selectedLevels');
      }
      this.getCourses();
    },
    selectedInstitutes(newVal) {
      this.coursesFetched = false;
      this.coursesPage = 1;
      this.data = [];
      if (newVal.length) {
        localStorage.setItem('selectedInstitutes', JSON.stringify(newVal));
      } else {
        localStorage.removeItem('selectedInstitutes');
      }
      this.getCourses();
    },
  },
  methods: {
    scrollTop() {
      this.$vuetify.goTo(0);
    },
    async getSkillsKits(nextPage?: boolean) {
      this.loading = true;
      if (!this.skillsKitsFetched || nextPage) {
        const { data } = await API.post(`/search/skills/?page=${this.skillsKitsDataPage}`, {
          search_term: this.searchTerm,
        });
        if (nextPage) {
          this.skillsKitsData = [...this.skillsKitsData, ...data.results];
        } else {
          this.skillsKitsData = data.results;
        }
        this.skillsKitsDataPage++;
        this.skillsKitsDataCount = data.count;
        this.skillsKitsFetched = true;
      }

      this.loading = false;
    },
    async getCourses(nextPage?: boolean) {
      this.loading = true;
      if (!this.coursesFetched || nextPage) {
        const { data } = await API.post(`/search/courses/?page=${this.coursesPage}`, {
          search_term: this.searchTerm,
          level: this.selectedLevels,
          institute: this.selectedInstitutes,
        });
        if (nextPage) {
          this.data = [...this.data, ...data.results];
        } else {
          this.data = data.results;
        }
        this.coursesPage++;
        this.coursesCount = data.count;
        this.coursesFetched = true;
      }
      this.loading = false;
    },
    async toggleSelected(id: string) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const skill = (this.skillsKitsData as any[]).find((skill) => skill.id === id);
      if (!skill.selected) {
        await API.post(`/search/select/${id}/`);
      } else {
        await API.delete(`/search/select/${id}/`);
      }
      skill.selected = !skill.selected;
      this.skillsKitsData = [...this.skillsKitsData];
    },
  },
  async mounted() {
    this.searchTerm = this.$route.query.search as string;
    if (localStorage.getItem('search_term') === this.searchTerm) {
      const skillsKits = localStorage.getItem('skillsKits');
      const selectedLevels = localStorage.getItem('selectedLevels');
      const selectedInstitutes = localStorage.getItem('selectedInstitutes');
      if (skillsKits) {
        this.skillsKits = JSON.parse(skillsKits) == false ? false : true;
      }
      if (selectedLevels) {
        this.selectedLevels = JSON.parse(selectedLevels) || [];
      }
      if (selectedInstitutes) {
        this.selectedInstitutes = JSON.parse(selectedInstitutes) || [];
      }
    } else {
      localStorage.setItem('search_term', this.searchTerm);
      localStorage.removeItem('selectedInstitutes');
      localStorage.removeItem('selectedLevels');
      localStorage.removeItem('skillsKits');
    }
  },
});
